<template>
  <v-sheet light color="grey lighten-4">
    <div class="px-8 pt-6 text-center mb-6">
      <h4 v-if="config" class="text-h4 font-weight-bold mb-2">
        {{ config.price / 1e2 | toCurrency }}
      </h4>
      <v-skeleton-loader v-else class="d-flex mb-4 justify-center" type="heading" />

      <p class="text-body-2 mb-6">
        Bitte gebe deine Daten ein, um mit der Zahlung fortzufahren.
      </p>

      <v-alert v-if="error" type="error" class="text-left">
        {{ error }}
      </v-alert>

      <v-form
        v-model="valid" :disabled="loading" class="mt-4 mb-2"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.firstName"
              outlined hide-details="auto"
              label="Vorname" autocomplete="given-name"
              :rules="rules.firstName"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.lastName"
              outlined hide-details="auto"
              label="Nachname" autocomplete="family-name"
              :rules="rules.lastName"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="form.country"
              outlined hide-details="auto"
              label="Land" :items="countries" autocomplete="country"
              :rules="rules.country"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.email"
              outlined hide-details="auto"
              label="E-Mail Addresse" type="email" autocomplete="email"
              :rules="rules.email"
            />
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="form.legal"
              hide-details="auto" class="ma-0"
              :rules="rules.legal"
            >
              <template #label>
                <p class="text-body-2 mb-0">
                  Ich habe die
                  <a href="/documents/agb.pdf" target="_blank" class="link" @click.stop>
                    AGB
                  </a>
                  und
                  <a href="/documents/datenschutz.pdf" target="_blank" class="link" @click.stop>
                    Datenschutzerklärung
                  </a>
                  gelesen und stimme zu.
                </p>
              </template>
            </v-checkbox>
          </v-col>

          <v-col cols="12">
            <p class="text-caption mb-0 text-center">
              Zahlungen werden sicher von PAYONE durchgeführt.
            </p>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-btn
      depressed color="green white--text" class="rounded-t-0 text-body-1 font-weight-bold"
      :disabled="!valid" height="64"
      :loading="loading" x-large block
      @click="onSubmit"
    >
      Weiter zur Zahlung
    </v-btn>
  </v-sheet>
</template>

<script>
import countries from 'i18n-iso-countries'
import sortBy from 'lodash.sortby'
import countryDataDe from 'i18n-iso-countries/langs/de.json'
import { handleErrorString } from '@/utils'

countries.registerLocale(countryDataDe)

const EMAIL_RE = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export default {
  data() {
    return {
      loading: false,
      valid: false,
      error: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        legal: false,
      },
      rules: {
        legal: [v => !!v || 'Pflichtfeld'],
        firstName: [v => !!v || 'Pflichtfeld', v => (v && v.length <= 50) || 'Unter 50 Zeichen'],
        lastName: [
          v => !!v || 'Pflichtfeld',
          v => (v && v.length >= 2) || 'Mindestens 2 Zeichen',
          v => (v && v.length <= 50) || 'Unter 50 Zeichen',
        ],
        email: [
          v => !!v || 'Pflichtfeld',
          v => EMAIL_RE.test(v) || 'E-Mail ungültig',
          v => (v && v.length <= 250) || 'Unter 250 Zeichen',
        ],
        country: [v => !!v || 'Pflichtfeld'],
      },
      config: null,
    }
  },
  computed: {
    countries() {
      const data = { ...countries.getNames('de', { select: 'official' }) }

      // prioritize DACH
      const order = { DE: 1, AT: 2, CH: 3 }

      const ordered = sortBy(
        Object.entries(data).map(([value, text]) => ({ text, value })),
        ({ value }) => order[value] ?? 99,
      )

      // add divider after DACH
      ordered.splice(3, 0, { divider: true })

      return ordered
    },
  },
  created() {
    this.getPrice()
  },
  methods: {
    async getPrice() {
      if (this.loading) return
      this.loading = true

      try {
        this.config = await this.$api.get('/config')
      } catch (error) {
        this.error = handleErrorString(error)
      } finally {
        this.loading = false
      }
    },
    async onSubmit() {
      if (!this.valid) return
      if (this.loading) return

      this.loading = true
      this.error = null

      try {
        const resp = await this.$api.post('/orders', this.form)
        document.location.href = resp.link
      } catch (error) {
        this.error = handleErrorString(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
